function scrollingText() {
	const scrolling_text = document.querySelectorAll('.scrolling_text'),
		windowWidth = window.innerWidth,
		desiredWidth = windowWidth * 2
	
	scrolling_text.forEach( (text) => {
		let wordsP = text.querySelector('p'),
			words = wordsP.innerHTML,
			width = wordsP.getBoundingClientRect().width,
			widthMod = width,
			newWords = words
		while(widthMod < windowWidth) {
			widthMod += width
			newWords += words
		}
		newWords += newWords
		wordsP.innerHTML = newWords	
		console.log(width)
	})
}
{
	const scrolling_text = document.querySelector('.scrolling_text')
	if(scrolling_text) { scrollingText() }
}