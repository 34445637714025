jQuery('.team_content_slider').slick({
	infinite: true,
	slidesToShow: 1,
	slidesToScroll: 1,
	dots: true,
	arrows : false,
	autoplay : true,
	autoplaySpeed: 4000,
	pauseOnHover: false,
	pauseOnFocus: false,
	asNavFor: '.team_image_slider',
});

jQuery('.team_image_slider').slick({
	infinite: true,
	slidesToShow: 1,
	slidesToScroll: 1,
	dots: false,
	arrows : false,
	autoplay : false,
	autoplaySpeed: 6000,
	pauseOnHover: false,
	pauseOnFocus: false,
	fade: true,
	asNavFor: '.team_content_slider',
});

jQuery('.logo_slider').slick({
	infinite: true,
	slidesToShow: 4,
	slidesToScroll: 1,
	dots: false,
	arrows : false,
	autoplay : true,
	autoplaySpeed: 3000,
	pauseOnHover: false,
	pauseOnFocus: false,
	responsive: [
		{
			breakpoint: 992,
			settings: {
				slidesToShow: 3,
				slidesToScroll: 1,
				infinite: true,
			}
		},
		{
			breakpoint: 768,
			settings: {
				slidesToShow: 2,
				slidesToScroll: 1
			}
		}
	]
});

